import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'

import {
  getTempOrder,
  createOrderAndStartPaymentAndWaitForResolvedPayment,
  localFilesHasBeenUploaded,
  uploadLocalFiles
} from 'actions'

import {
  fromOrderStateToSyncableOrder,
} from 'actions/order'

import {
  getOrderRowBundles
} from 'selectors'

import routes from 'routes'

import { Translate } from 'containers/translates'
import { OneColumnLayout } from 'components/layouts'
import DocumentMeta from 'components/documentMeta'
import { PageHeading } from 'components/content'

import OrderRowsSection from 'components/Order/OrderRowsSection'
import OrderDetailsSection from 'components/Order/OrderDetailsSection'
import CustomerSection from 'components/Order/CustomerSection'
import DeliverySection from 'components/Order/DeliverySection'
import PaymentSection from 'components/Order/PaymentSection'
import SummarySection from 'components/Order/SummarySection'

import { scrollToTop } from '../hoc'
function getSectionState(stateBools) {
  const firstTrueIndex = findIndex(stateBools, Boolean)

  return [
    'disabled',
    'active',
    'cleared'
  ][firstTrueIndex]
}

function OrderPage({
  getTempOrder,
  createOrderAndStartPaymentAndWaitForResolvedPayment,
  order,
  customer,
  orderRowBundles,
}) {
  const [activeSection, setActiveSection] = React.useState(0)

  function getSectionStates() {
    const orderIsNotEmpty = order.rows.length > 0
    const detailsIsSetup = order.useDate !== null

    const orderHaveValidCustomerId = order.customerId !== null

    const hasSelectedDeliveryMethodAndDeliveryAddress = (
      order.deliveryMethodId !== null &&
      order.customerIdForDelivery !== null
    )

    const hasSelectedPaymentMethodAndInvoiceAddress = (
      order.paymentMethodId !== null &&
      order.customerIdForInvoice !== null
    )

    const hasConfiguredSelectedPaymentMethod = (
      hasSelectedPaymentMethodAndInvoiceAddress &&
      (
        (order.paymentMethodId === 'EFAKTURA' && order.reference !== null) ||
        (order.paymentMethodId !== 'EFAKTURA' && true)
      )
    )

    const s1 = orderIsNotEmpty
    const s2 = s1 && detailsIsSetup
    const s3 = s2 && orderHaveValidCustomerId
    const s4 = s3 && hasSelectedDeliveryMethodAndDeliveryAddress
    const s5 = s4 && hasSelectedPaymentMethodAndInvoiceAddress && hasConfiguredSelectedPaymentMethod
    const s6 = false

    const sectionStates = [
      getSectionState([!s1, !s1, s1]),
      getSectionState([!s1, s1 && !s2, s2]),
      getSectionState([!s2, s2 && !s3, s3]),
      getSectionState([!s3, s3 && !s4, s4]),
      getSectionState([!s4, s4 && !s5, s5]),
      getSectionState([!s5, s5 && !s6, s6]),
    ]

    return sectionStates
  }

  function syncOrder(customer, order) {
    getTempOrder(
      customer,
      fromOrderStateToSyncableOrder(order)
    )
  }

  function submitOrder() {
    createOrderAndStartPaymentAndWaitForResolvedPayment()
  }

  function updateActiveSectionState() {
    const currentActiveState = activeSection
    const newActiveSection = findIndex(getSectionStates(), x => x === 'active')

    if (currentActiveState < newActiveSection) {
      setActiveSection(newActiveSection)
    }
  }

  React.useEffect(() => {
    const orderIsSyncable = (
      order.customerId !== null &&
      order.rows.length > 0 &&
      order.useDate !== null
      // order.deliveryMethodId !== null &&
      // order.paymentMethodId !== null &&
    )

    let timeoutId = null

    if (orderIsSyncable) {
      timeoutId = setTimeout(() => {
        syncOrder({
          id: order.customerId,
          // type: customer.customerType
        }, order)
      }, 1000)
    }

    updateActiveSectionState()

    return function cleanup() {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [
      order.rows,
      order.customerId,
      order.deliveryMethodId,
      order.paymentMethodId,
      order.useDate,
      // customer.customerType
    ]
  )

  const sectionStates = getSectionStates()

  return (
    <Translate ns="order">
      {({ t }) => (
        <React.Fragment>
          <DocumentMeta
            title={t('title')}
            url={routes.ORDER.get()}
            noindex
          />
          <Translate ns="sections">
            {({ t }) => (
              <OneColumnLayout>
                <PageHeading>{t('header.heading')}</PageHeading>
                <OrderRowsSection sectionState={sectionStates[0]} />
                <OrderDetailsSection sectionState={sectionStates[1]} />
                <CustomerSection sectionState={sectionStates[2]} />
                <DeliverySection sectionState={sectionStates[3]} />
                <PaymentSection sectionState={sectionStates[4]} />
                <SummarySection
                  onSubmit={submitOrder}
                  sectionState={sectionStates[5]}
                />
              </OneColumnLayout>
            )}
          </Translate>
        </React.Fragment>
      )}
    </Translate>
  )
}

OrderPage.propTypes = {
  getTempOrder: PropTypes.func.isRequired,
  createOrderAndStartPaymentAndWaitForResolvedPayment: PropTypes.func.isRequired,
  uploadLocalFiles: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  orderRowBundles: PropTypes.array.isRequired,
}

export default connect(state => {
  return {
    order: state.order,
    customer: state.customer,
    user: state.user,
    orderRowBundles: getOrderRowBundles(state),
  }
}, {
    createOrderAndStartPaymentAndWaitForResolvedPayment,
    getTempOrder,
    localFilesHasBeenUploaded,
    uploadLocalFiles,
  })(scrollToTop()(OrderPage))
