import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'

import {
  signinWithToken,
  signinFailed,
  refreshUser
} from 'actions'

import {
  orderApi
} from 'api'

import { Translate } from 'containers/translates'
import ContentHeader from 'components/ContentHeader'

import { DarkButton, ButtonContainer, SecondaryButton, PrimaryButton } from 'components/buttons'
import GetToken from 'containers/steps/GetToken'
import SigninOrRegisterModal from 'components/modals/SigninOrRegisterModal'
import UnionNodeSearchForm from 'components/forms/UnionNodeSearchForm';
import CreateUnionForm from 'components/forms/CreateUnionForm';
import CreateCompanyForm from 'components/forms/CreateCompanyForm';

import LoadingIndicator from 'components/spinners'
import { CUSTOMER_TYPES, SORTED_CUSTOMER_TYPES, standardizeCustomer } from 'selectors/customer';
import { LoadingLayerContainer } from 'components/LoadingLayer';
import useBoolState from 'hooks/useBoolState';
import { userIsSignedInAndLoaded } from 'selectors/user';
import CreateIndividualCustomerForm from 'components/forms/CreateIndividualCustomerForm';
import CustomerForm from 'components/CustomerForm';
import promiseHandler from 'containers/hoc/promiseHandler';
import ModalWithClose, { ModalBody, SIZES } from 'components/modals/Modal';
import { refreshCustomer } from 'actions/index';
import CreateCommunityForm from 'components/forms/CreateCommunityForm'


function UserCustomerInformationAndEditModal({ customer, t, onSubmitForm }) {
  const [editUserCustomerDialogIsOpen, openEditUserCustomerDialog, closeEditUserCustomerDialog] = useBoolState(false)

  const handleSubmitForm = React.useCallback((formValues) => {
    onSubmitForm(formValues)
      .then(closeEditUserCustomerDialog)
  }, [customer])

  return (
    <React.Fragment>
      <p>
        {customer.street} <br />
        {customer.zip} {customer.city} <br />
        {customer.mobile} <br />
        {customer.email} <br />
        <DarkButton
          className="mt-2"
          size="sm"
          outline
          onClick={openEditUserCustomerDialog}
        >
          {t('editUserCustomerButtonLabel')}
        </DarkButton>
      </p>
      <ModalWithClose
        isOpen={editUserCustomerDialogIsOpen}
        onClose={closeEditUserCustomerDialog}
      >
        <ModalBody size={SIZES.LARGE}>
          <CustomerForm
            onSubmit={handleSubmitForm}
            initialData={customer}
          />
        </ModalBody>
      </ModalWithClose>
    </React.Fragment>
  )
}

UserCustomerInformationAndEditModal.propTypes = {
  customer: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired
}

const STEPS = {
  INIT: 'INIT',
  USER: 'USER',
  SELECT_CUSTOMER_OR_CUSTOMER_TYPE: 'SELECT_CUSTOMER_OR_CUSTOMER_TYPE',
  SEARCH_CUSTOMER: 'SEARCH_CUSTOMER',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  EDIT_INDIVIDUAL_CUSTOMER: 'EDIT_INDIVIDUAL_CUSTOMER',
  SELECTED_CUSTOMER: 'SELECTED_CUSTOMER',
}

function OrderCustomerSelection({
  userIsSignedInAndLoaded, user,
  customer,
  onSelectCustomer,
  onSelectCustomerType,
  onResetCustomer,
  selectedCustomerId,
  selectedCustomerType,
  signinWithToken,
  signinFailed,
  refreshUser,
  refreshCustomer,
  makePromise
}) {

  const [step, setStep] = React.useState(null)
  const [newUnionName, setNewUnionName] = React.useState('')
  const [registerUserDialogIsOpen, openRegisterUserDialog, closeRegisterUserDialog] = useBoolState(false)
  const [continueWithNoUser, setContinueWithNoUser] = React.useState(null)

  const [isCreatingCustomer, setIsCreatingCustomer, setIsNotCreatingCustomer] = useBoolState(false)

  const reset = React.useCallback(() => {
    setStep(STEPS.USER)
    setContinueWithNoUser(null)
    onResetCustomer()
  }, [])


  function handleCreateCustomer({ type, name, activities, ...more }) {
    setIsCreatingCustomer()
    orderApi.createCustomer({
      type,
      name,
      activities,
      ...more
    })
      .then(({ createdIds }) => {
        onSelectCustomer(createdIds[0])
        setIsNotCreatingCustomer()
      })
  }

  function handleIndividualCustomer(formValues) {
    const customerId = user.customer.id
    return makePromise(
      orderApi.editCustomer(
        customerId,
        formValues
      )
    ).then(() => {
      onSelectCustomer(customerId)

      if (userIsSignedInAndLoaded) {
        refreshUser()
      }
      else {
        refreshCustomer()
      }
      setStep(STEPS.SELECTED_CUSTOMER)
    });
  }

  React.useEffect(() => {
    if (selectedCustomerId) {
      setStep(STEPS.SELECTED_CUSTOMER)
      return
    }

    if (selectedCustomerType) {
      setStep({
        [CUSTOMER_TYPES.UNION]: STEPS.SEARCH_CUSTOMER,
        [CUSTOMER_TYPES.COMPANY]: STEPS.CREATE_CUSTOMER,
        [CUSTOMER_TYPES.COMMUNITY]: STEPS.CREATE_CUSTOMER,
        [CUSTOMER_TYPES.INDIVIDUAL]: userIsSignedInAndLoaded ? STEPS.EDIT_INDIVIDUAL_CUSTOMER : STEPS.CREATE_CUSTOMER,
      }[selectedCustomerType])
      return
    }


    if (userIsSignedInAndLoaded || continueWithNoUser) {
      setStep(STEPS.SELECT_CUSTOMER_OR_CUSTOMER_TYPE)
      return
    }

    setStep(STEPS.USER)

  }, [
    selectedCustomerId,
    selectedCustomerType,
    continueWithNoUser,
    userIsSignedInAndLoaded
  ]
  )

  const renderFn = {
    [STEPS.USER]() {
      return (
        <Translate ns="user">
          {({ t }) => (
            <React.Fragment>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <ContentHeader headingNo={3} />
                  <div className="mt-3">
                    <ButtonContainer>
                      <SecondaryButton size="lg" onClick={openRegisterUserDialog}>{t('registerUserButtonLabel')}</SecondaryButton>
                      <DarkButton outline onClick={() => setContinueWithNoUser(true)}>{t('continueWithoutUserButtonLabel')}</DarkButton>
                    </ButtonContainer>
                  </div>
                </Col>
                <Col xs={12} sm={12} className="d-md-none">
                  <hr />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Translate ns="signin">
                    {({ t }) => (
                      <React.Fragment>
                        <ContentHeader headingNo={3} />
                        <div className="mt-3">
                          <GetToken
                            t={t}
                            onSuccess={signinWithToken}
                            onFailure={signinFailed}
                          />
                        </div>

                      </React.Fragment>
                    )}
                  </Translate>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Translate>
      )
    },
    [STEPS.SELECT_CUSTOMER_OR_CUSTOMER_TYPE]() {

      const userCustomerHaveOrderedBefore = userIsSignedInAndLoaded && user.customer.numOfOrders > 0

      const customerSelections = (userIsSignedInAndLoaded ? [
        ...(user.customerAccess.filter(({ state }) => state === 'approved')).map(({ customer }) => customer),
        ...(userCustomerHaveOrderedBefore ? [user.customer] : []),
      ] : [])

      return (
        <Translate ns="start">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader headingNo={3} />
              <Row className="mt-3">
                <Col xs={12} sm={12} md={6}>
                  {customerSelections.length > 0 && (
                    <div className="mb-3">
                      <ButtonContainer >
                        {customerSelections.map((customer) => (
                          <PrimaryButton
                            key={customer.id}
                            size="lg"
                            onClick={() => {
                              onSelectCustomer(customer.id)
                            }}
                          >
                            {t('customerType', { context: customer.type })}: {customer.name}
                          </PrimaryButton>
                        ))}
                      </ButtonContainer>
                      {/* <hr className="my-3" /> */}
                    </div>
                  )}
                  <h4>{t('selectCustomerTypeHeader')}</h4>
                  <ButtonContainer>
                    {SORTED_CUSTOMER_TYPES
                      .filter((customerType) => (
                        !(
                          userCustomerHaveOrderedBefore &&
                          customerType === CUSTOMER_TYPES.INDIVIDUAL
                        )
                      ))
                      .map((customerType) => (
                        <DarkButton
                          key={customerType}
                          size="lg"
                          outline
                          onClick={() => {
                            onSelectCustomerType(customerType)
                          }}
                        >
                          {t('customerType', { context: customerType })}
                        </DarkButton>
                      )
                      )}
                  </ButtonContainer>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Translate>
      )
    },
    [STEPS.SEARCH_CUSTOMER]() {
      const customerTypeRenderMapping = {
        [CUSTOMER_TYPES.UNION]() {
          return (
            <Translate ns="union.search">
              {({ t }) => (
                <React.Fragment>
                  <ContentHeader headingNo={3} />
                  <div className="mt-3">
                    <Row>
                      <Col xs={12} sm={12} md={6}>
                        <UnionNodeSearchForm
                          t={t}
                          onSelect={({ id }) => {
                            onSelectCustomer(id)
                          }}
                          onCreate={({ name }) => {
                            setNewUnionName(name)
                            setStep(STEPS.CREATE_CUSTOMER)
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              )}
            </Translate>
          )
        }
      }

      return customerTypeRenderMapping[selectedCustomerType]()
    },
    [STEPS.CREATE_CUSTOMER]() {
      const FormComponent = {
        [CUSTOMER_TYPES.UNION]: CreateUnionForm,
        [CUSTOMER_TYPES.COMPANY]: CreateCompanyForm,
        [CUSTOMER_TYPES.COMMUNITY]: CreateCommunityForm,
        [CUSTOMER_TYPES.INDIVIDUAL]: CreateIndividualCustomerForm
      }[selectedCustomerType];

      return (
        <Translate ns={`${selectedCustomerType}.create`}>
          {({ t }) => (
            <React.Fragment>
              <ContentHeader headingNo={3} />
              <div className="mt-3">
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <LoadingLayerContainer isLoading={isCreatingCustomer}>
                      <FormComponent
                        prefilledInputValues={{ name: newUnionName }}
                        onSubmit={handleCreateCustomer}
                        t={t}
                      />
                    </LoadingLayerContainer>
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          )}
        </Translate>
      )
    },
    [STEPS.EDIT_INDIVIDUAL_CUSTOMER]() {

      const { name, email, mobile, street, zip, city } = standardizeCustomer(customer)

      return (
        <Translate ns={`${selectedCustomerType}.edit`}>
          {({ t }) => (
            <React.Fragment>
              <ContentHeader headingNo={3} />
              <div className="mt-3">
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <CustomerForm
                      initialData={{
                        name, email, mobile, street, zip, city
                      }}
                      onSubmit={handleIndividualCustomer}
                      t={t}
                    />
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          )}
        </Translate>
      )
    },
    [STEPS.SELECTED_CUSTOMER]() {
      const standardizedCustomer = standardizeCustomer(customer)
      return (
        <Translate>
          {({ t }) => (
            <React.Fragment>
              <h3>{t('customerType', { context: standardizedCustomer.type })}: {standardizedCustomer.name}</h3>
              {
                !customer.isSyncing &&
                standardizedCustomer.type === CUSTOMER_TYPES.INDIVIDUAL &&
                (
                  <React.Fragment>
                    <div className="mt-3">
                      <p>
                        {standardizedCustomer.street} <br />
                        {standardizedCustomer.zip} {standardizedCustomer.city} <br />
                        {standardizedCustomer.mobile} <br />
                        {standardizedCustomer.email} <br />
                        <DarkButton
                          className="mt-2"
                          size="sm"
                          outline
                          onClick={() => setStep(STEPS.EDIT_INDIVIDUAL_CUSTOMER)}
                        >
                          {t('editUserCustomerButtonLabel')}
                        </DarkButton>
                      </p>
                    </div>
                  </React.Fragment>
                )}
              <div className="mt-3">
                <DarkButton onClick={reset} outline>
                  {t('resetCustomerSelectionButtonLabel')}
                </DarkButton>
              </div>
            </React.Fragment>
          )}
        </Translate>
      )
    },
  }[step] || (function Loading() {
    return (
      <LoadingIndicator />
    )
  })

  return (
    <LoadingLayerContainer isLoading={customer.isSyncing}>
      <React.Fragment>
        {renderFn()}
        <SigninOrRegisterModal
          isOpen={registerUserDialogIsOpen}
          includeCompleteStep={false}
          onClose={closeRegisterUserDialog}
          onDone={closeRegisterUserDialog}
        />
      </React.Fragment>
    </LoadingLayerContainer>
  )
}

OrderCustomerSelection.propTypes = {
  // Other props
  onSelectCustomer: PropTypes.func.isRequired,
  onSelectCustomerType: PropTypes.func.isRequired,
  onResetCustomer: PropTypes.func.isRequired,
  selectedCustomerId: PropTypes.number,
  selectedCustomerType: PropTypes.string,

  // Redux props
  userIsSignedInAndLoaded: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  signinWithToken: PropTypes.func.isRequired,
  signinFailed: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired,
  refreshCustomer: PropTypes.func.isRequired,

  // From promiseHandler
  makePromise: PropTypes.func.isRequired
}

export default connect((state) => ({
  user: state.user,
  customer: state.customer,
  userIsSignedInAndLoaded: userIsSignedInAndLoaded(state)
}), {
  signinWithToken,
  signinFailed,
  refreshUser,
  refreshCustomer,
})(promiseHandler(OrderCustomerSelection))
