import React from 'react';

import { caseSelector } from 'utils/helpers';
import { CUSTOMER_TYPES } from 'selectors/customer';

import stepStateComposer from 'containers/hoc/stepStateComposer';
import { Translate } from 'containers/translates';

import ContentHeader from 'components/ContentHeader';
import CustomerTypeForm from 'components/forms/CustomerTypeForm';
import UnionNodeSearchForm from 'components/forms/UnionNodeSearchForm';
import CreateUnionForm from 'components/forms/CreateUnionForm';
import CreateCompanyForm from 'components/forms/CreateCompanyForm';
import CreateCommunityForm from 'components/forms/CreateCommunityForm';


const STEPS = {
  TYPE_SELECTION: 'STEPS.TYPE_SELECTION',
  UNION: {
    SEARCH: 'STEPS.UNION.SEARCH',
    CREATE: 'STEPS.UNION.CREATE',
  },
  COMPANY: {
    SEARCH: 'STEPS.COMPANY.SEARCH',
    CREATE: 'STEPS.COMPANY.CREATE',
  },
  COMMUNITY: {
    CREATE: 'STEPS.COMMUNITY.CREATE',
  },
}

const UnionOrCompanyCreatingAndOrSelecting = stepStateComposer(
  class _UnionOrCompanyCreatingAndOrSelecting extends React.Component {
    constructor(props) {
      super(props);

      this.onSelectCustomerType = this.onSelectCustomerType.bind(this);
      this.onSelectCustomer = this.onSelectCustomer.bind(this);
      this.onCreateCustomer = this.onCreateCustomer.bind(this);
      this.onSelectCreateUnion = this.onSelectCreateUnion.bind(this);
    }

    onSelectCustomerType(customerType) {
      switch (customerType) {
        case CUSTOMER_TYPES.UNION:
          this.props.setStep(STEPS.UNION.SEARCH);
          break;
        case CUSTOMER_TYPES.COMPANY:
          this.props.setStep(STEPS.COMPANY.CREATE);
          break;
        case CUSTOMER_TYPES.COMMUNITY:
          this.props.setStep(STEPS.COMMUNITY.CREATE);
          break;
      }
    }

    onSelectCustomer(id) {
      // this.props.onSelect(id);

      this.props.onSubmit({ id });
      // DONE!
    }

    onSelectCreateUnion({ name }) {
      this.props.setStep(STEPS.UNION.CREATE, undefined, {
        data: {
          name
        }
      });
    }

    onCreateCustomer(data) {
      this.props.onSubmit(data);
    }

    componentDidMount() {
      this.props.setStep(STEPS.TYPE_SELECTION, null);
    }

    render() {

      const { step, stepData, stepState } = this.props;

      return (
        <Translate ns="select">
          {() => (
            <React.Fragment>
              {caseSelector({
                [STEPS.TYPE_SELECTION]: () => (
                  <Translate ns="typeSelection">
                    {() => (
                      <React.Fragment>
                        <ContentHeader />
                        <hr className="my-4" />
                        <CustomerTypeForm
                          customerTypes={[
                            CUSTOMER_TYPES.UNION,
                            CUSTOMER_TYPES.COMPANY,
                            CUSTOMER_TYPES.COMMUNITY,
                          ]}
                          onSubmit={this.onSelectCustomerType}
                          buttonProps={{
                            outline: true,
                            size: "lg"
                          }}
                        />
                      </React.Fragment>
                    )}
                  </Translate>
                ),
                [STEPS.UNION.SEARCH]: () => (
                  <Translate ns="union.search">
                    {({ t }) => (
                      <React.Fragment>
                        <ContentHeader />
                        <hr className="my-4" />
                        <UnionNodeSearchForm
                          onSelectId={this.onSelectCustomer}
                          onCreate={this.onSelectCreateUnion}
                          t={t}
                        />
                      </React.Fragment>
                    )}
                  </Translate>
                ),
                [STEPS.UNION.CREATE]: () => (
                  <Translate ns="union.create">
                    {({ t }) => (
                      <React.Fragment>
                        <ContentHeader />
                        <hr className="my-4" />
                        <CreateUnionForm
                          prefilledInputValues={{
                            name: stepData.name
                          }}
                          // onCreated={this.onSelectCustomer}
                          onSubmit={this.onCreateCustomer}
                          t={t}
                        />

                      </React.Fragment>
                    )}
                  </Translate >
                ),
                [STEPS.COMPANY.CREATE]: () => (
                  <Translate ns="company.create">
                    {({ t }) => (
                      <React.Fragment>
                        <ContentHeader />
                        <hr className="my-4" />
                        <CreateCompanyForm
                          // onCreated={this.onSelectCustomer}
                          onSubmit={this.onCreateCustomer}
                          t={t}
                        />

                      </React.Fragment>
                    )}
                  </Translate>
                ),
                [STEPS.COMMUNITY.CREATE]: () => (
                  <Translate ns="community.create">
                    {({ t }) => (
                      <React.Fragment>
                        <ContentHeader />
                        <hr className="my-4" />
                        <CreateCommunityForm
                          onSubmit={this.onCreateCustomer}
                          t={t}
                        />

                      </React.Fragment>
                    )}
                  </Translate>
                )
              }, () => (
                <p>{step}-{stepState} not yet implemented</p>
              ))(step)()}
            </React.Fragment>
          )}
        </Translate>
      )
    }
  }
)


export default UnionOrCompanyCreatingAndOrSelecting;
