import { ORDER, CUSTOMER } from '../actions';

const initState = {
  id: null,
  customerId: null,
  customerIdForDelivery: null,
  customerIdForInvoice: null,
  sendInvoiceBy: 'delivery',
  availableDeliveryMethods: [],
  availablePaymentMethods: [],
  feeRows: [],
  amount: {},
  deliveryMethodId: null,
  paymentMethodId: null,
  useDate: null,
  name: null,
  reference: null,
  comment: null,
  discountCode: null,
  discountCodeError: null,
  discountRows: [],
  discountData: {},
  rows: [],
  nextRowId: 0,
  data: {},
  sendState: null,
  isSyncing: false,
  localFilesIsBeeingUploaded: false,
  localFilesHasBeenUploaded: false,
};

const orderRowInitState = {
  id: null,
  articleId: null,
  quantity: 1,
  unitPrice: null,
  inputValues: [],
  optionSelectionPicks: [],
  productFieldInputs: []
}

const inputValues = (state = orderRowInitState.inputValues, action) => {
  switch (action.type) {
    case ORDER.ROWS.ADD:
    case ORDER.ROWS.ADD_MULTIPLE:
    case ORDER.ROWS.EDIT:
      if (action.inputValues) {
        return [...action.inputValues]
      }
      else {
        return state
      }
    default:
      return state;
  }
}

const optionSelectionPicks = (state = orderRowInitState.optionSelectionPicks, action) => {
  switch (action.type) {
    case ORDER.ROWS.ADD:
    case ORDER.ROWS.ADD_MULTIPLE:
    case ORDER.ROWS.EDIT:
      if (action.optionSelectionPicks) {
        return [...action.optionSelectionPicks]
      }
      else {
        return state
      }
    default:
      return state;
  }
}

const productFieldInputs = (state = orderRowInitState.productFieldInputs, action) => {
  switch (action.type) {
    case ORDER.ROWS.ADD:
    case ORDER.ROWS.ADD_MULTIPLE:
    case ORDER.ROWS.EDIT:
      if (action.productFieldInputs) {
        return [...action.productFieldInputs]
      }
      else {
        return state
      }
    default:
      return state;
  }
}

const quantity = (state = orderRowInitState.quantity, action) => {
  switch (action.type) {
    case ORDER.ROWS.ADD:
    case ORDER.ROWS.ADD_MULTIPLE:
    case ORDER.ROWS.EDIT:
      if (action.quantity) {
        return action.quantity;
      }
      else {
        return state
      }
    default:
      return state;
  }
}

const unitPrice = (state = orderRowInitState.unitPrice, action) => {
  switch (action.type) {
    case ORDER.ROWS.ADD:
    case ORDER.ROWS.ADD_MULTIPLE:
    case ORDER.ROWS.EDIT:
      if (action.unitPrice) {
        return action.unitPrice;
      }
      else {
        return state
      }
    default:
      return state;
  }
}

const row = (state = orderRowInitState, action) => {

  switch (action.type) {
    case ORDER.ROWS.ADD:
    case ORDER.ROWS.ADD_MULTIPLE:
      return {
        ...state,
        id: action.id,
        articleId: action.articleId,
        quantity: quantity(undefined, action),
        unitPrice: unitPrice(undefined, action),
        inputValues: inputValues(undefined, action),
        optionSelectionPicks: optionSelectionPicks(undefined, action),
        productFieldInputs: productFieldInputs(undefined, action),
      };

    case ORDER.ROWS.EDIT:
      if (state.id === action.id) {
        return {
          ...state,
          quantity: quantity(state.quantity, action),
          unitPrice: unitPrice(state.unitPrice, action),
          inputValues: inputValues(state.inputValues, action),
          optionSelectionPicks: optionSelectionPicks(state.optionSelectionPicks, action),
          productFieldInputs: productFieldInputs(state.productFieldInputs, action),
        }
      }
      else {
        return state;
      }

    default:
      return state;
  }
}

const rows = (state = initState.rows, action) => {
  switch (action.type) {
    case ORDER.ROWS.ADD:
      return [
        ...state,
        row(undefined, action)
      ];
    case ORDER.ROWS.ADD_MULTIPLE:
      return [
        ...state,
        ...(action.rows.map((_row) => row(undefined, {
          ...action,
          ..._row
        })))
      ]
    case ORDER.ROWS.EDIT:
      return state.map(r => row(r, action));
    case ORDER.ROWS.REMOVE:
      return state.filter(({ id }) => id !== action.id)
    case ORDER.ROWS.RESET:
      return initState.rows;
    default:
      return state;
  }
}

const nextRowId = (state = 0, action) => {
  switch (action.type) {
    case ORDER.ROWS.ADD:
      return state + 1;
    case ORDER.ROWS.ADD_MULTIPLE:
      return state + action.rows.length;
    default:
      return state;
  }
}

// const data = (state = initState.data, action) => {
//   switch (action.type) {
//     case ORDER.FETCH.RECEIVE:
//       return { ...action.data };
//     default:
//       return state;
//   }
// }

const deliveryMethodId = (state = initState.deliveryMethodId, action) => {
  switch (action.type) {
    case ORDER.SET_DELIVERY_METHOD:
      return action.deliveryMethodId;
    case CUSTOMER.RESET:
      return initState.deliveryMethodId;
    default:
      return state;
  }
}

const paymentMethodId = (state = initState.paymentMethodId, action) => {
  switch (action.type) {
    case ORDER.SET_PAYMENT_METHOD:
      return action.paymentMethodId;
    case CUSTOMER.RESET:
      return initState.paymentMethodId;
    default:
      return state;
  }
}

const useDate = (state = initState.useDate, action) => {
  switch (action.type) {
    case ORDER.SET_USE_DATE:
      return action.useDate;
    default:
      return state;
  }
}

const name = (state = initState.name, action) => {
  switch (action.type) {
    case ORDER.SET_NAME:
      return action.name;
    default:
      return state;
  }
}

const reference = (state = initState.reference, action) => {
  switch (action.type) {
    case ORDER.SET_REFERENCE:
      return action.reference;
    default:
      return state;
  }
}

const comment = (state = initState.comment, action) => {
  switch (action.type) {
    case ORDER.SET_COMMENT:
      return action.comment;
    default:
      return state;
  }
}

const discountCode = (state = initState.discountCode, action) => {
  switch (action.type) {
    case ORDER.SET_DISCOUNT_CODE:
      return action.discountCode;
    default:
      return state;
  }
}

const discountCodeError = (state = initState.discountCodeError, action) => {
  switch (action.type) {
    case ORDER.TEMP_FETCH.RECEIVE:
      return action.data.discountCodeError;
    case ORDER.SET_DISCOUNT_CODE:
      return initState.discountCodeError;
    default:
      return state;
  }
}


const localFilesIsBeeingUploaded = (state = initState.localFilesIsBeeingUploaded, action) => {
  switch (action.type) {
    case ORDER.UPLOAD_LOCAL_FILES:
      return true
    case ORDER.LOCAL_FILES_HAS_BEEN_UPLOADED:
      return false
    default:
      return state
  }
}

const localFilesHasBeenUploaded = (state = initState.localFilesHasBeenUploaded, action) => {
  switch (action.type) {
    case ORDER.UPLOAD_LOCAL_FILES:
      return false
    case ORDER.LOCAL_FILES_HAS_BEEN_UPLOADED:
      return true
    default:
      return state
  }
}

const order = (state = initState, action) => {
  switch (action.type) {
    case ORDER.SET_USE_DATE:
    case ORDER.SET_COMMENT:
    case ORDER.SET_NAME:
    case ORDER.SET_REFERENCE:
    case ORDER.SET_DELIVERY_METHOD:
    case ORDER.SET_PAYMENT_METHOD:
    case ORDER.SET_DISCOUNT_CODE:
      return {
        ...state,
        useDate: useDate(state.useDate, action),
        name: name(state.name, action),
        reference: reference(state.reference, action),
        comment: comment(state.comment, action),
        discountCode: discountCode(state.discountCode, action),
        discountCodeError: discountCodeError(state.discountCodeError, action),
        deliveryMethodId: deliveryMethodId(state.deliveryMethodId, action),
        paymentMethodId: paymentMethodId(state.paymentMethodId, action),
      }
    case ORDER.UPLOAD_LOCAL_FILES:
    case ORDER.LOCAL_FILES_HAS_BEEN_UPLOADED:
      return {
        ...state,
        localFilesIsBeeingUploaded: localFilesIsBeeingUploaded(state.localFilesIsBeeingUploaded, action),
        localFilesHasBeenUploaded: localFilesHasBeenUploaded(state.localFilesHasBeenUploaded, action)
      }
    case ORDER.SET_CUSTOMER:
      return {
        ...state,
        customerId: action.customerId,
        customerIdForDelivery: action.customerIdForDelivery,
        customerIdForInvoice: action.customerIdForInvoice,
        sendInvoiceBy: action.sendInvoiceBy
      }
    case ORDER.SET_DELIVERY_ADDRESS:
      return {
        ...state,
        customerIdForDelivery: action.id,
      }
    case ORDER.SET_INVOICE_ADDRESS:
      return {
        ...state,
        customerIdForInvoice: action.id,
      }
    case CUSTOMER.RESET:
      return {
        ...state,
        customerId: initState.customerId,
        customerIdForDelivery: initState.customerIdForDelivery,
        customerIdForInvoice: initState.customerIdForInvoice,
        sendInvoiceBy: initState.sendInvoiceBy,
        deliveryMethodId: deliveryMethodId(state.deliveryMethodId, action),
        paymentMethodId: paymentMethodId(state.paymentMethodId, action),
        availableDeliveryMethods: initState.availableDeliveryMethods,
        availablePaymentMethods: initState.availablePaymentMethods,
        feeRows: initState.feeRows,
        discountRows: initState.discountRows,
        discountData: initState.discountData,
        amount: initState.amount,
        localFilesIsBeeingUploaded: initState.localFilesIsBeeingUploaded,
        localFilesHasBeenUploaded: initState.localFilesHasBeenUploaded,
      }
    case ORDER.ROWS.ADD:
    case ORDER.ROWS.ADD_MULTIPLE:
    case ORDER.ROWS.EDIT:
    case ORDER.ROWS.REMOVE:
    case ORDER.ROWS.RESET:
      return {
        ...state,
        nextRowId: nextRowId(state.nextRowId, action),
        rows: rows(state.rows, action),
      };
    // case ORDER.FETCH.RECEIVE:
    //   return {
    //     ...state,
    //     id: action.data.id,
    //     data: data(state.data, action),
    //   }

    case ORDER.TEMP_FETCH.REQUEST:
      return {
        ...state,
        // availableDeliveryMethods: initState.availableDeliveryMethods,
        // availablePaymentMethods: initState.availablePaymentMethods,
        // feeRows: initState.feeRows,
        // amount: initState.amount,
        isSyncing: true
      }
    case ORDER.TEMP_FETCH.RECEIVE:
      return {
        ...state,
        availableDeliveryMethods: action.data.availableDeliveryMethods,
        availablePaymentMethods: action.data.availablePaymentMethods,
        feeRows: action.data.feeRows,
        discountCodeError: discountCodeError(state.discountCodeError, action),
        discountRows: action.data.discountRows,
        discountData: action.data.discountData || initState.discountData,
        amount: action.data.amount,
        isSyncing: false
      }
    case ORDER.SEND:
      return {
        ...state,
        sendState: "sending"
      };

    case ORDER.SENT:
      return {
        ...state,
        sendState: "sent"
      };

    case ORDER.RESET:
      return initState;

    default:
      return state;
  }
}

export default order;