import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOMER_TYPES } from 'selectors/customer';
import CustomerForm from 'components/forms/CustomerForm';


export default function CreateCommunityForm({ onSubmit, prefilledInputValues = {}, t }) {
  const handleSubmit = React.useCallback((inputValues) => {
    return onSubmit({
      ...inputValues,
      type: CUSTOMER_TYPES.COMMUNITY,
    })
  }, [onSubmit])

  return (
    <CustomerForm
      prefilledInputValues={prefilledInputValues}
      fields={['name', 'registrationNumber']}
      onSubmit={handleSubmit}
      t={t}
    />
  )
}

CreateCommunityForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  prefilledInputValues: PropTypes.object,
  t: PropTypes.func.isRequired,
}