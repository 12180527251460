export const CUSTOMER_TYPES = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
  UNION: 'union',
  COMMUNITY: 'community',
}

export const SORTED_CUSTOMER_TYPES = [
  CUSTOMER_TYPES.UNION,
  CUSTOMER_TYPES.COMMUNITY,
  CUSTOMER_TYPES.COMPANY,
  CUSTOMER_TYPES.INDIVIDUAL,
];


export const customer = ({ customer }) => customer;

export const customerId = (state) => customer(state).id;
export const customerExist = (state) => customerId(state) !== null;

export const customerType = (state) => customer(state).customerType;

export const customerData = (state) => customer(state).data;
export const customerName = (state) => customerData(state).name;

export const standardizeCustomer = (customer) => {
  if (customer.data) {
    return customer.data
  }

  return customer
}