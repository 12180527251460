import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { DarkButton } from 'components/buttons'
import { Translate } from 'containers/translates'
import Address from 'components/Address'
import Input from 'components/forms/Input'

import PaymentMethodNameAndDescription from './PaymentMethodNameAndDescription'
import { callbackWithoutPropagationComposer } from 'utils/event'

import { setReference } from 'actions'
import { connect } from 'react-redux';
import Form, { FormButton, FormGroup, LabeledFormInput } from 'components/forms/Form'

const PaymentMethodMainColumn = connect((state) => ({
  orderReference: state.order.reference,
}), { setReference })(function PaymentMethodMainColumn({
  paymentMethod,
  isSelected,
  showInvoiceAddress,
  showInvoiceAddressIsOtherThanDeliveryAddress,
  invoiceAddressIsOtherThanDeliveryAddress,
  onChangeInvoiceAddressIsOtherThanDeliveryAddress,
  invoiceAddress,
  onChangeInvoiceAddress,
  orderReference,
  setReference,
}) {

  const renderedNameAndDescription = React.useMemo(() => (
    <PaymentMethodNameAndDescription
      {...{
        paymentMethod: paymentMethod,
        isSelected: isSelected
      }}
    />
  ), [paymentMethod, isSelected])

  const [nextOrderReference, setNextOrderReference] = React.useState(orderReference)
  useEffect(() => {
    if (orderReference) {
      setNextOrderReference(orderReference)
    }
  }, [orderReference])


  if (showInvoiceAddress) {
    return (
      <Translate>
        {({ t }) => (
          <React.Fragment>
            {renderedNameAndDescription}
            {isSelected && (

              <React.Fragment>
                {/* {showInvoiceAddressIsOtherThanDeliveryAddress && (
                  <div className="mt-2">
                    <label>
                      <input
                        type="checkbox"
                        onChange={onChangeInvoiceAddressIsOtherThanDeliveryAddress}
                        checked={invoiceAddressIsOtherThanDeliveryAddress}
                        {...{}}
                      /> {t('invoiceAddressIsOtherThanDeliveryAddressLabel')}
                    </label>
                  </div>
                )} */}
                {
                  invoiceAddress !== undefined && (
                    <div className="mt-2">
                      <h4 className={showInvoiceAddressIsOtherThanDeliveryAddress && !invoiceAddressIsOtherThanDeliveryAddress ? "font-italic text-muted" : ""}>{invoiceAddress.name}</h4>
                      <Address
                        ghost={showInvoiceAddressIsOtherThanDeliveryAddress && !invoiceAddressIsOtherThanDeliveryAddress}
                        showName={false}
                        showContactInformation
                        {...invoiceAddress}
                      />
                      {invoiceAddressIsOtherThanDeliveryAddress && (
                        <DarkButton
                          outline
                          size="sm"
                          onClick={callbackWithoutPropagationComposer(onChangeInvoiceAddress)}
                        >
                          {t('changeButtonLabel')}
                        </DarkButton>
                      )}
                    </div>
                  )
                }
                {showInvoiceAddressIsOtherThanDeliveryAddress && !invoiceAddressIsOtherThanDeliveryAddress && (
                  <div className="mt-2">
                    <DarkButton
                      size="sm"
                      outline
                      onClick={onChangeInvoiceAddressIsOtherThanDeliveryAddress}
                    >
                      {t('changeToInvoiceIsOtherThanDeliveryAddressButtonLabel')}
                    </DarkButton>
                  </div>
                )}

                {paymentMethod.id === 'EFAKTURA' && (
                  <div className="mt-4">
                    <Form
                      key="order-reference-form"
                      t={t}
                      valueRules={[
                        {
                          key: "orderReference",
                          required: true
                        },
                      ]}
                      onSubmit={(values) => {
                        setReference(values.orderReference)
                      }}
                      prefilledInputValues={{
                        orderReference: orderReference
                      }}
                    >
                      {() => {
                        return (
                          <React.Fragment>
                            <FormGroup>
                              <LabeledFormInput
                                {...{ t }}
                                inputName="orderReference"
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormButton color="primary">
                                {t('submit.label')}
                              </FormButton>
                            </FormGroup>
                          </React.Fragment>
                        )
                      }}
                    </Form>
                  </div>

                  // <div className="mt-2">
                  //   <label className="d-block mb-1">
                  //     {t('fields.orderReference.label')}
                  //   </label>
                  //   <Input
                  //     key="field.orderReference"
                  //     onChange={(nextValue) => {
                  //       setNextOrderReference(nextValue)
                  //     }}
                  //     onBlur={() => {
                  //       setReference(nextOrderReference || null)
                  //     }}
                  //     value={nextOrderReference}
                  //     validationErrors={!nextOrderReference ? [{error: "Hej"}] : undefined}
                  //     className={!orderReference ? "border-danger" : undefined}
                  //   />
                  //   {!orderReference && <div className="alert alert-danger mt-2">
                  //     {t('fields.orderReference.isRequiredError')}
                  //   </div>}
                  // </div>
                )}
              </React.Fragment>


            )}
          </React.Fragment>
        )}
      </Translate>
    )
  }
  else {
    return renderedNameAndDescription
  }
})

PaymentMethodMainColumn.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showInvoiceAddress: PropTypes.bool.isRequired,
  showInvoiceAddressIsOtherThanDeliveryAddress: PropTypes.bool,
  invoiceAddressIsOtherThanDeliveryAddress: PropTypes.bool,
  onChangeInvoiceAddressIsOtherThanDeliveryAddress: PropTypes.func,
  onChangeInvoiceAddress: PropTypes.func,
  invoiceAddress: PropTypes.object,
}

export default PaymentMethodMainColumn